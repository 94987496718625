import { sanitizeUrl } from '@braintree/sanitize-url';

export const sanitizeLtkUrl = (url?: string) => {
  const { LEGACY_HOME_PAGE } = window.rewardStyle;

  if (typeof url !== 'string') {
    return LEGACY_HOME_PAGE;
  }

  const sanitizedUrl = sanitizeUrl(url.trim());
  try {
    const parsedUrl = new URL(sanitizedUrl).hostname;
    const allowedHosts = [
      'creator.shopltk.com',
      'collaborations.shopltk.com',
      'qa-marshall-rsthecom-v2.qashopltk.com',
      'qa-johnson-rsthecom-v2.qashopltk.com',
      'qa-kennedy-rsthecom-v2.qashopltk.com',
      'creator.qashopltk.com',
      'collaborations.qashopltk.com',
      'dev-rsthecom-v2.ltk.rip',
      'dev-kennedy-rsthecom-v2.ltk.rip',
      'local-rsthecom-v2.qashopltk.com',
      'local-rsthecom-v2.ltk.rip',
    ];
    if (allowedHosts.includes(parsedUrl)) {
      return sanitizedUrl;
    } else {
      return LEGACY_HOME_PAGE;
    }
  } catch (e) {
    return LEGACY_HOME_PAGE;
  }
};
