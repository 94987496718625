import { RouteConfig } from 'vue-router';
import { PRODUCTS_SEARCH, LTK_PRODUCT_LINK, PRODUCT_SET, GIFT_GUIDE } from '@/utils/router/namedRoutes';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';
import { ExperimentName, ExperimentType } from '@/types/experiments';

const routes: RouteConfig[] = [
  {
    path: '/ltk-product-link',
    name: LTK_PRODUCT_LINK,
    component: () => import(/* webpackChunkName: "ltk-link" */ '../views/ProductLink.vue'),
  },
  {
    path: '/products/search',
    name: PRODUCTS_SEARCH,
    beforeEnter: async (to, from, next) => {
      await validateRoute([], to, from, next);
    },
    component: () => import(/* webpackChunkName: "products-v2" */ '../views/ProductsSearch.vue'),
  },
  {
    path: '/productset',
    name: PRODUCT_SET,
    component: () => import(/* webpackChunkName: "product-set" */ '../views/ProductSet.vue'),
    meta: {
      experiment: {
        name: ExperimentName.PUBLISH_PRODUCT_SETS,
        type: ExperimentType.FLAG,
        allowed: ['on'],
      },
    },
  },
  {
    path: '/giftguide',
    name: GIFT_GUIDE,
    component: () => import(/* webpackChunkName: "gift-guide" */ '../views/GiftGuide.vue'),
    meta: {
      experiment: {
        name: ExperimentName.PUBLISH_PRODUCT_SETS,
        type: ExperimentType.FLAG,
        allowed: ['on'],
      },
    },
  },
];

export default routes;
