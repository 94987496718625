import { NavigationGuard } from 'vue-router';
import { DOWNLOAD_APP } from '@/utils/router/namedRoutes';
import { isMobileUserAgent } from '@/utils/device';

export const checkMobile: NavigationGuard = async function (to, from, next) {
  const ua = navigator.userAgent;
  const mobileDeviceRegex = isMobileUserAgent(ua);
  if (!mobileDeviceRegex) {
    return next();
  }

  return next({ name: DOWNLOAD_APP });
};
